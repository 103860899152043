import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

interface MegaMenuProps {
  items: Array<{
    title: string;
    description?: string;
    path?: string; // Make path optional
    icon?: string;
    subItems?: Array<{
      title: string;
      path?: string; // Make path optional
      icon?: string;
    }>;
  }>;
  title: string;
  onMouseLeave?: () => void;
}

const MegaMenu: React.FC<MegaMenuProps> = ({ items, title, onMouseLeave }) => {
  const navigate = useNavigate();

  const handleNavigate = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="submenu"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ x: -300, opacity: 0 }}
        transition={{ duration: 1 }}
        className="flex flex-col relative min-w-[60vh] left-0 mt-2 p-2 bg-black rounded-lg  shadow-lg z-50 "
        onMouseLeave={onMouseLeave}
      >
        <IoMdClose
          onClick={onMouseLeave}
          className=" place-self-end md:hidden right-5 p-1 h-[25px] w-[25px] top-3 text-lg border rounded-full text-black"
        />

        <div className="p-3">
          <div className="flex flex-col ">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex flex-col justify-center mb-4 bg-black-100 p-1  rounded-lg"
              >
                {item.path ? (
                  <h3
                    className="font-semibold flex justify-between items-center text-white  px-2 py-1 text-sm uppercase mb-2 cursor-pointer hover:text-blue-600"
                    onClick={() => handleNavigate(item.path)}
                  >
                    {item.title}
                  </h3>
                ) : (
                  <h3 className="font-semibold flex justify-between items-center text-white  px-2 py-1 text-sm uppercase mb-2">
                    {item.title}
                  </h3>
                )}
                {item.description && (
                  <p className="text-sm text-white mb-4">
                    {item.description}
                  </p>
                )}
                {item.subItems && (
                  <ul className="space-y-2 pl-3 border-">
                    {item.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`cursor-pointer text-[15px] p-1 text-white hover:underline  hover:text-blue-600 ${
                          subItem.path ? "cursor-pointer" : ""
                        }`}
                        onClick={() => handleNavigate(subItem.path)}
                      >
                        {subItem.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
          <div className="mt-4 pt-4 border-t border-gray-200 flex justify-between items-center">
            <button
              onClick={() =>
                window.open("https://console.suburbancloud.ng", "_blank")
              }
              className="flex items-center text-sm text-red-600 hover:text-gray-800 font-normal"
            >
              Console
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MegaMenu;
